.Termos .terms {
    margin-top:35px;
   
    font-size: 14px !important; 
    text-align: justify;
    height: 250px;
    overflow: auto;
}

.Termos h1 {
    text-align: center;
    color: #333;
    font-size:21px;
}

.Termos h2 {
    color: #0082C9;
    border-bottom: 2px solid #0082C9;
    padding-bottom: 5px;
    font-size:18px;
}

.Termos p {
    margin-bottom: 15px;
    padding-right: 15px;
}

.Termos ul {
    margin: 10px 0;
    padding: 0 20px;
    list-style-type: none;
}

.Termos ul li {
    margin-bottom: 10px;
}


.letter-list {
    list-style-type: none; /* Remove os marcadores padrão */
    counter-reset: list-counter; /* Reseta o contador */
    padding-left: 2em;
}

.letter-list li {
    counter-increment: list-counter; /* Incrementa o contador para cada li */
    position: relative;
    margin-bottom: 15px;
}

.letter-list li::before {
    content: counter(list-counter, upper-alpha) ". "; /* Adiciona o índice de letra antes do conteúdo */
    font-weight: bold; /* Negrito opcional */
    position: absolute; /* Remove do fluxo normal do documento */
    left: -2em;
}

.letter-list li p {
    margin: 0; /* Remove a margem padrão do parágrafo */
    padding-left: 1em; /* Adiciona padding para identar o conteúdo */
    text-indent: -1em; /* Indenta o texto para alinhar com o início do parágrafo */
}