.pull-left {
    float: left;
}

.form-control {
    width: 100%;
}

.hide {
    display: none;
}

.clear {
    clear: both ;
}


.fg-label sup a {
    color:blue;
    cursor: pointer;
}

.form-floating-btn {
    float: right;
    position: relative;
    margin-top: -48px;
    margin-right: 8px;
}


.form-floating-btn button {
    margin-left: 5px;
}


.form-floating-btn {
    
}

.form-field-required_empty {
    border-color: #e1665d !important;
}




@media (max-width: 700px) {
    .GeneratedForm .form-group {
        width: 100% !important;
    }


}



