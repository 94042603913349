.Payment {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Payment .container-fluid {
    width: 900px;
}


.Payment .radio  {
    display: grid;
    grid-template-columns: 30px  150px;
}


.Payment .radio label {
    margin-top: -2px;
}


.Cadastro {
    background-color: #0082C9;
}



@media (min-width:900px){
    .Cadastro .container-fluid {
        max-width:900px;
    }
}
@media (max-width:900x){
    .Cadastro .container-fluid {
        max-width:100%;
    }
}



.Cadastro {
    text-align: left !important;
}

.hide {
    display: none;
}

.Cadastro .radio {
    float:left;
    padding: 0 0 0 0;
    align-items: center;
    display: flex;
}


.Cadastro .radio label {
    margin-top: 10px;
    margin-left: 10px;
}

.separatorbar {
    background-color: #F0F0F0;
    border-radius:10px;
    margin-top: 10px;
}


.col-space {
    margin-top: 25px !important;
}


.user_cards {
    display: flex;
    background-color: #F7F7F7;
    border-radius: 11px;
    height: 160px;
    align-items: center;
    justify-content: center;
}


.pay-item-top-grid {
    display: grid;
    grid-template-columns: auto 175px;
}

.pay-item-top-grid button {
    width: 100%;
}


.select-btn{
    width:35px;
    height:35px;
    line-height:40px;
    text-align:center;
    position:absolute;
    right:66px;
    margin-top:-50px
}


.checkTerms {
    display: grid;
    grid-template-columns: 38px auto;
}

.checkTerms div {
    display: flex;
    align-items: center;
}

.checkTerms input[type='checkbox'] {
    width:26px;
    height:26px;
}


.Payment .submit[disabled]
{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}



  .Payment .top_pagante
{
        display: grid;
        grid-template-columns: auto auto 140px;
  }


  .Payment .top_pagamento
  {
       display: grid;
       grid-template-columns: auto auto 140px;
 }


 .Payment .separatorbar_actions {
        display: flex;
        align-items: center;
        font-size: 13px !important;
  }



.Payment .div_plano_description {
    margin-bottom:20px;
}